/* CSS Reset */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "roboto";
  src: url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

:root{
  --red-team-bg:#f61314;
  --purple-team-bg:#b149fa;
  --blue-team-bg:#05a1de;
  --dark-navy: #1B263B;
  --cyber-blue: #0D6EFD;
  --slate-gray: #6C757D;
  --electric-cyan: #00FFFF;
  --white-smoke: #F5F5F5;
}

html,body {
  box-sizing: border-box;
  font-family: "roboto", Mono ;
  font-size:16px;
  background-color: var(--white-smoke);
  color: var(--slate-gray);
}

header, footer {
  background-color: var(--dark-navy);
  color: var(--white-smoke);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--dark-navy);
}

a {
  color: var(--white-smoke);
}


button {
  padding: 15px 40px !important;
  border-radius: 10px !important;
  font-weight: bold !important;
}

.btn-primary{
  background-color: var(--cyber-blue);
  color: var(--white-smoke);
}

.bg-purple-team{
  background-color: var(--purple-team-bg);
}
.bg-blue-team{
  background-color: var(--blue-team-bg);
}
.bg-red-team{
  background-color: var(--red-team-bg);
}

h1{
  font-size:2.986rem;
}
h2{
  font-size:2.488rem;
}
h3{
  font-size:2.074rem;
}
h4{
  font-size:1.728rem;
}
h5{
  font-size:1.44rem;
}
h6{
  font-size:1.2rem;
}

input::placeholder , textarea::placeholder{
  padding-left:3px;
}

/* Navbar */
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 21%;
  width: 100%;
  padding: 0 3%;
  position: fixed;
  z-index: 1;
  transition: all 0.7s;
  font-size: 1.2em;
}

.background {
  transition: all 0.7s;
  height: 13%;
}

.logo-div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.logo-div > img {
  height: 40%;
}

.logo-title {
  font-weight: bold;
  color: var(--white-smoke);
}

.nav > ul {
  display: flex;
  gap: 40px;
  list-style: none;
}

.link {
  text-decoration: none;
}

.activeLink {
  color: var(--electric-cyan);
  border-bottom: 1px solid var(--electric-cyan);
  padding-bottom: 1px;
}

.navbar-services li {
  padding: 12px 0;
  list-style: none;
  text-align: center;
  border-bottom: 1px solid rgb(99, 99, 99);
}


.hashLink {
  text-decoration: none;
}

#red-team {
  border-radius: 10px 10px 0px 0px;
}

#purple-team {
  border-radius: 0px 0px 10px 10px;
}

.nav > ul > li {
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}

.nav-img {
  height: 70%;
}

.burger-icon-open {
  display: none;
}

.burger-menu {
  display: none;
}

.hidden-burger-menu {
  display: none;
}

.landingPage {
  display: flex;
  padding-top: 10%;
  justify-content: center;
  align-items: center;
  background-color: var(--dark-navy);
  min-height: 100vh;
}

#ring-image{
  width: 600px;
}

.secure-crown-btn{
  margin-top: 2%;
  font-size: 1.5em;
  border-width: 0;
  align-self: left;
  /* letterSpacing: 1; */
}

/* Services */
.services {
  display: flex;
  gap: 40px;
  padding: 3% 3%;
}

.company-info {
  border: solid #f42240 3px;
  flex: 1;
  text-align: center;
  padding: 5% 0 2% 0;
  color: rgb(84, 84, 84);
  max-width: 450px;
}

.company-info > p {
  width: 70%;
  margin: auto;
  margin-bottom: 20px;
  font-size: 1.6em;
  line-height: 1.7em;
  color: rgb(200, 200, 200);
}

.company-info > img {
  height: 230px;
}

.mobile-accordion {
  display: none;
}

.our-services {
  flex: 2;
}

.our-services h2 {
  text-align: center;
  margin-bottom: 80px;
}

/* Testimonial */
.testimonial {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10% 0 5% 0;
}

.testimonial-text {
  border: 2px solid #3266cb;
  background-color: #f42240;
  width: 50%;
  margin-left: -28px;
  padding: 60px 30px;
  border-radius: 10px;
}

/* packages */
.packages {
  padding: 3% 3%;
  width: 100%;
  background-color: var(--dark-navy);
}

.packages h2 {
  text-align: center;
  margin-bottom: 80px;
  color: var(--white-smoke);
}

.package-items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2%;
  width: 100%;
}

.package {
  border-radius: 10px;
  flex-basis: 23%;
  height: 370px;
  text-align: center;
  padding: 10px;
  box-shadow: 5px 5px var(--cyber-blue);
}

.package h4 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.package p {
  width: 80%;
  margin: auto;
}

/* About Us Page */
.about-us {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10%;
  background: url("./assets/AboutUs/wipfli.gif"); /* we can add multiple images url()*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.our-story {
  padding: 120px 0;
}

.team-member {
  border-radius: 20px;
  background-color: #f42240;
  text-align: center;
}

.team-member p {
  padding: 30px 0;
  font-size: 1.3em;
}

.member-image {
  border-radius: 20px 20px 0 0;
  height: 100%;
  width: 100%;
}

/* Contact Us Page */
.contact-us-screen {

  background: url("./assets/ContactUs/cyber.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.contact-us-wrapper{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10%;
  background-color: #1b263bc9;
  padding: 30px;
  border-radius: 10px;
  width:500px;
  margin-top: 75px;
}

/* Footer */
.footer {
  height: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--dark-navy);
  color: var(--white-smoke);
}
@media (width <= 1500px) {
  .package{
    flex-basis: 48%;
    margin-bottom: 2%;
    height:250px;
  }
}
/* Media query */
@media (width <= 1024px) {
  html,body {
    font-size:12px;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2.1rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1rem;
  }
  /* Navbar */
  /* .nav h1{
    font-size: 1.5rem;
  } */
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 100%;
    padding: 0 10px;
    position: fixed;
    z-index: 1;
    transition: all 0.7s;
  }

  .background {
    transition: all 0.7s;
    height: 70px;
  }

  .logo-div {
    min-width: 10px;
    padding-left: 0;
  }

  .logo-div-short {
    transform: scale(0.8, 0.8);
    padding-left: 0;
    margin-left: -20px;
    /* gap: 5px; */
  }
  
  .nav > ul {
    display: none;
  }

  .burger-icon-open {
    display: block;
  }


  .burger-icon-open:active {
    background-color: rgba(30, 30, 30, 0.5);
  }

  .burger-icon-close:active {
    background-color: rgba(50, 50, 50, 0.5);
  }

  .burger-menu {
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: var(--dark-navy);
    width: 50%;
    height: 100vh;
    transition: right 0.7s;
  }

  .hidden-burger-menu {
    display: block;
    position: absolute;
    right: -50%;
    top: 0px;
    background-color: var(--dark-navy);
    width: 50%;
    height: 100vh;
    transition: right 0.7s;
  }

  .burger-menu > ul {
    font-size: 25px;
    padding-top: 20px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    list-style: none;
  }

  .hidden-burger-menu > ul {
    font-size: 25px;
    padding-top: 20px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;

    list-style: none;
  }

  /* Landing Page */

  .landingPage {
    display: flex;
    padding-top: 30%;
    padding-bottom: 20%;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }
  #ring{
    display:flex;
    flex-direction: row;
    justify-content: center;
  }
  #ring-image{
    width: 300px;
  }
  .landingPage h3{
    text-align: center;
  }
  .secure-crown-btn{
    font-size: 1rem;
    align-self: center;
  }

  .contact-us-wrapper{
    width:300px;
  }

  /* Services */
  .services {
    display: block;
  }

  .company-info {
    border: 0;
    padding-top: 0;
    max-width: 1000px;
    margin-bottom: 50px;
  }

  .company-info > p {
    width: 88%;
    margin: auto;
    margin-bottom: 10px;
    font-size: 1.2em;
    /* line-height: 1em; */
    color: rgb(200, 200, 200);
  }

  .company-info > img {
    height: 100px;
  }

  .our-services {
    display: none;
  }

  .mobile-accordion {
    display: block;
    margin-top: 20px;
  }

  .mobile-accordion h2 {
    margin: 10px 0 15px 0;
    text-align: center;
    margin-bottom: 20px;
  }
  .mobile-accordion

  /* Testimonials */
  .testimonial {
    padding-top: 60px;
  }
  .testimonial img {
    display: none;
  }

  .testimonial-text {
    width: 80%;
    margin-left: 0;
    padding: 7% 7%;
  }


  .packages h1 {
    margin-bottom: 30px;
  }


  .three-packages {
    gap: 35px;
  }

  .package {
    border-radius: 10px;
    text-align: center;
    padding: 25px 10px;
    flex-basis: 100%;
    margin-bottom: 4%;
    height:200px;
  }

  .package h4 {
    color: #3266cb;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .package h5 {
    width: 90%;
    margin: auto;
  }


  /* Footer */
  .footer {
    height: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column-reverse;
    padding-bottom: 5px;
  }

  .footer ul {
    flex-wrap: nowrap;
  }
}


.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Visually hide the H1 but keep it accessible for SEO */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
